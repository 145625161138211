// @import '../../styles/_variables.scss';
@import '../../styles/_variables';

.login-signup {
    display: flex;
    justify-content: space-between;

    .login-form {
        display: flex;
        justify-content: center;
        align-items: center;
        align-content: center;
        height: 100vh;
    }

    .login-img {
        width: 100%;
        height: 100vh;
        object-fit: cover;
    }

    .right-side {
        width: 50%;
        flex: 50%;
        height: 100vh;
        // background: linear-gradient(90deg, rgba(158,43,133,1) 0%, rgba(158,43,133,1) 40%, rgba(53,51,170,1) 100%);
        background: $button-background;
        .main-text{
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 36px;
            color: white;
            font-weight: 600;
            img{
                height: 120px;
                width: 240px;
                filter: brightness(0) invert(1);
            }
        }
    }

    .left-side {
        width: 50%;
    }

    .parent {
        width: 60%;
    }

    .login-btn-section {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .forget-text {
            font-size: 18px;
        }

        .login-btn {
            width: 35%;
            border: 0px;
        }
    }

    .no-accoint {
        font-size: 16px;
        text-align: center;
    }

    .logo-parent {
        text-align: center;
        font-size: 22px;
        font-weight: 600;
    }

    input {
        height: 45px;
        border-radius: 0px;
        border: 2px solid $input-border-color
    }

    .ant-input-affix-wrapper {
        padding: 0px 11px !important;
        border-radius: 0px;
        border: 2px solid $input-border-color;
    }

    .draw-line {
        height: 3px;
        width: 100%;
        background: $button-background;
    }

    /* Change Autocomplete styles in Chrome*/
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus input:-webkit-autofill,
    textarea:-webkit-autofill,
    textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
    select:-webkit-autofill,
    select:-webkit-autofill:hover,
    select:-webkit-autofill:focus {
        -webkit-box-shadow: 0 0 0px 1000px white inset;
        transition: background-color 5000s ease-in-out 0s;
    }
}

.signup {
    display: flex;
    justify-content: space-between;
    .ant-form-item-extra{
        font-size: 13px;
    }
    .login-form {
        display: flex;
        justify-content: center;
        align-items: center;
        align-content: center;
        height: 100vh;
    }

    .login-img {
        width: 100%;
        height: 100vh;
        object-fit: cover;
    }

    .right-side {
        width: 60%;
        flex: 60%;
        height: 106vh;
        background: linear-gradient(90deg, rgba(158,43,133,1) 0%, rgba(158,43,133,1) 40%, rgba(53,51,170,1) 100%);
        .main-text{
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            color: white;
            font-weight: 700;
        }
        .ul-li-section{
            font-weight: 500;
            color: white;
            list-style: none;
              
            li::before {
                content: "\2022";
                color: #white;
                font-size: 22px;
                line-height: 16px;
                display: inline-block; 
                width: 1em;
                margin-left: -1em;
            }
            li{
                padding: 8px;
                font-size: 16px;
            }
        }
    }

    .left-side {
        width: 40%;
        margin-right: 35px;
    }

    .parent {
        width: 75%;
    }

    .login-btn-section {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .forget-text {
            font-size: 18px;
        }

        .login-btn {
            width: 35%;
            border: 0px;
        }
    }

    .no-accoint {
        font-size: 16px;
        text-align: center;
    }

    .logo-parent {
        text-align: center;
        font-size: 22px;
        font-weight: 600;
    }

    input {
        height: 45px;
        border: 1px solid rgba(0, 0, 0, 0.3);
        border-radius: 4px;
    }

    .ant-select-selector{
        height: 45px !important;
        border: 1px solid rgba(0, 0, 0, 0.3) !important;
        border-radius: 4px !important;
    }
    .ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-item, .ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-placeholder, .ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector:after{
        line-height: 45px !important;
    }

    .draw-line {
        height: 3px;
        width: 100%;
        background: $button-background;
    }

    /* Change Autocomplete styles in Chrome*/
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus input:-webkit-autofill,
    textarea:-webkit-autofill,
    textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
    select:-webkit-autofill,
    select:-webkit-autofill:hover,
    select:-webkit-autofill:focus {
        -webkit-box-shadow: 0 0 0px 1000px white inset;
        transition: background-color 5000s ease-in-out 0s;
    }
}

@media (max-width: $screen-md) {
   .signup{
        display: block;
        .right-side{
            width: 100%;
            height: auto;
            .main-text{
                padding-top: 50px;
                padding-bottom: 50px;
                display: block;
                margin-left: 50px;
                margin-right: 50px;
                .fs38{
                    font-size: 26px !important;
                }
            }
        }
        .left-side{
            width: 100%;
            margin-bottom: 100px;
            .login-form{
                height: auto;
            }
        }
   }
}

.forget-template {
    .center-section {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100vh;

        .center-section-child {
            width: 35%;
            @media (max-width: $screen-sm) {
                width: 90%;
              }
        }

        .title {
            font-size: 28px;
            font-weight: 500;
        }

        .sub-title {
            font-size: 24px;
            font-weight: 500;
        }

        .sub-title-2 {
            font-size: 18px;
        }
    }

    .login-btn-section {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .forget-text {
            font-size: 18px;
        }

        .login-btn {
            border: 0px;
        }
    }

    input {
        height: 45px;
        border-radius: 0px;
        border: 2px solid $input-border-color;
        font-size: 15px;
    }

    .ant-input-affix-wrapper {
        padding: 0px 11px !important;
        border-radius: 0px;
        border: 2px solid $input-border-color;
        font-size: 15px;
    }
    .ant-input[disabled]{
        color: rgba(0, 0, 0, 0.7) !important;
    }
    input::placeholder {
        color:rgba(0, 0, 0, 0.5) !important
    }
}
.otp-modal{
    background-color: red;
    input{
        width: 35px !important;
        height: 35px;
        border: 2px solid $input-border-color;
        font-size: 20px;
    }
    .otp-section{
        align-items: center;
        justify-content: center;
        display: flex;
    }
    .ant-modal-body{
        text-align: center;
    }

    span{
        color: $input-border-color;
    }
}