.admin-content {
    .sub-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .ant-input-affix-wrapper {
        padding: 0px 11px;
    }

    .title {
        font-size: 18px;
        font-weight: 600;
    }

    .prize-img {
        object-fit: cover;
        width: 50px;
        height: 50px;
    }

    .ant-input-search {
        .ant-input-search-button {
            height: 40px;
        }
    }

    .ant-input {
        height: 40px;
        border-radius: 0px;
    }

    .tab {
        background-color: #f0f2f5;
        margin-bottom: 5px;
        color: rgb(0 0 0 / 50%);
        padding: 15px 10px;
        width: 100%;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        .active {
            color: rgb(0 0 0 / 100%);
            border-bottom: 3px solid transparent;
            border-image: $button-background;
            border-image-slice: 1;

        }

        p {
            padding-right: 10px;
            padding-left: 10px;
            margin-right: 10px;
            margin-left: 10px;
            margin-bottom: 0px;
            font-weight: 600;
            padding: 5px 0px;

            span {
                cursor: pointer;
                font-size: 16px;
            }
        }

        .badge {
            margin-left: 7px;

            .ant-badge.ant-badge-not-a-wrapper {
                span {
                    font-size: 14px !important;
                }
            }
        }

        @media screen and (min-width: $screen-xxl) {
            font-size: 20px;
        }
    }

    .rigt-navigation {

        .parent {
            display: flex;
        }

        .child {
            text-align: center;
            width: 300px;

            .title {
                margin-bottom: 0px;
                font-size: 14px;
                color: rgba(0, 0, 0, 0.67);
                font-weight: 500;
            }

            .anticon-check {
                width: 25px;
                height: 25px;
                border-radius: 50%;
                background: rgba(0, 0, 0, 0.1);
                display: inline-flex;
                align-items: center;
                justify-content: center;
                font-size: 18px;
            }

            .icon {
                margin-bottom: 7px;
            }

            .blank {
                svg {
                    path {
                        display: none;
                    }
                }
            }

            &.done {
                .anticon-check {
                    background: rgba(47, 115, 31, 0.2);
                    border: 2px solid #2F731F;

                    svg {
                        font-size: 17px;
                        color: #2F731F;
                    }
                }

                .title {
                    color: rgba(0, 0, 0, 0.87);
                }
            }

            &.inprogress {
                .anticon-check {
                    background: #fff;
                    color: rgba(0, 0, 0, 0.87);
                    border: 3px solid #2F731F;
                }

                .title {
                    color: rgba(0, 0, 0, 0.87);
                }
            }
        }

        .comment {
            position: absolute;
            margin-top: -10px;
            margin-left: 23px;
        }
    }

    .display-text {
        font-weight: bold;
        color: rgba(0, 0, 0, 0.87);
    }

    .display-subtext {
        color: rgba(0, 0, 0, 0.87);

    }

    .admin-fundraiser-detail {

        .update-left {
            padding: 0px 30px 30px;

            .left-child {
                border: 1px solid rgba(0, 0, 0, 0.1);
                padding: 15px 30px 40px 30px;

                textarea.ant-input {
                    height: auto;
                    border: 1px solid #d9d9d9;
                }
            }

            .links{
                color: #1B35B3;
                font-weight: bold;
                font-size: 16px;            
            }
          
            .button {
                display: flex;
            }
        }

        .preview-org {
            border: 1px solid rgba(0, 0, 0, 0.1);
            border-radius: 8px;
            padding: 24px;
        }

        .main-card {
            width: 700px;
            border: 1px solid rgba(0, 0, 0, 0.1);
            border-radius: 8px;

            &.active {
                border: 3px solid #1B35B3;
            }
        }

        .camp-card {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        .ant-collapse {
            border: none;
        }

        .ant-space-item {
            box-shadow: 0 2px 5px rgb(0 0 0 / 20%);
            border-radius: 5px;
        }

        .ant-collapse-content,
        .ant-collapse>.ant-collapse-item {
            border-top: none;
            border-bottom: none;
        }

        .ant-collapse-header {
            color: #1B35B3;
            font-weight: bold;
            font-size: 16px;
            background: white;
            border: none;
            border-top-right-radius: 8px;
            border-top-left-radius: 8px;
            padding: 15px 20px 15px 15px;

            .ant-collapse-arrow {
                font-size: 17px;
                vertical-align: -3px;
                font-weight: bold;
            }

            .anticon.anticon-edit {
                font-size: 18px;
                vertical-align: -4px;
            }
        }

        .ant-collapse-content {
            border-bottom-right-radius: 8px;
            border-bottom-left-radius: 8px;
        }

        .preview-payment-card {
            display: flex;
            height: 100%;
            align-items: center;
            border: 1px solid rgba(0, 0, 0, 0.1);
            border-radius: 8px;
            padding: 20px;
            justify-content: space-between;

            .text {
                font-weight: 600;
                font-size: 16px;
                color: rgba(0, 0, 0, 0.87);
            }

            .vis-ending {
                color: rgba(0, 0, 0, 0.6);
            }
        }
    }

    .clear-filter {
        vertical-align: middle;
        margin-right: 15px;
    }

    .permission-table {
        .ant-table-row-expand-icon-cell {
            svg {
                font-size: 20px !important;
                display: flex !important;
                cursor: pointer !important;
            }
        }

        .row-class-name {
            background: #fafafa;
            &.tied{
                background:antiquewhite !important;
            }
        }

        .row-class-name td:nth-child(2) {
            font-size: 16px;
            font-weight: bold;
        }

    }

    .campaign-table {
        .ant-badge-count {
            background: $button-background;
        }
    }

    //Related to game
    .game-analytics {
        .cards-box {
            width: 100%;
            margin-right: 0px !important;

            &.analytics {
                .parent {
                    text-align: center;
                }
            }
            
        }
        .tied {       
            background:antiquewhite !important;
        }
        .space-box {
            .ant-space-item {
                width: 100% !important;
            }
        }

        .game-detail-analytics-card {
            text-align: center;
        }

        .card-prize-img {
            height: 200px;
            width: 200px;
            object-fit: cover;
            margin-right: 15px;
        }

        .pr0px {
            padding-right: 0px !important;
        }

        .ant-card-bordered {
            border: 2px solid rgba(0, 0, 0, 0.1);
            border-radius: 8px;
        }

        .game-prize-card {
            width: 100%;
        }

        .design-prize-card {
            display: flex;
            justify-content: space-between;

            .section1 {
                display: flex;
                align-items: center;
            }
        }

        .section2 {
            .ant-tag {
                padding: 2px 12px;
                font-size: 17px !important;
                border-radius: 8px !important;
            }
        }

    }

}

.fundraising-content {
    padding-top: 0px;

    .sub-header {
        font-weight: 600;
        font-size: 18px;
        line-height: 36px;
        color: #000000;
        padding-left: 100px;
        padding-right: 100px;
    }

    .new-fundraising {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 65vh;
        text-align: center;

        &_child {
            width: 32%;
        }

        .new-btn {
            padding: 0px 30px;
        }

        img {
            width: 100px;
        }

        .title {
            font-size: 18px;
            font-weight: 600;
            color: black;
        }

        .sub-title {
            font-size: 16px;
            color: rgba(0, 0, 0, 0.6);
        }
    }

    .new-fundraising-parent {
        padding-left: 100px;
        padding-right: 100px;
        margin-top: 15px;

        .ant-input-affix-wrapper {
            border-top-left-radius: 8px !important;
            border-bottom-left-radius: 8px !important;
            height: 45px;
        }

        .action{
            position: absolute;
            right: -25px;
        }
        .edit-fundraiser {
            // position: absolute;
            right: 0px;
            // top: -13px;
            font-size: 18px;
            height: 22px;
            width: 22px;
            align-items: center;
            display: flex;
            justify-content: center;
            border-radius: 50%;
            color: black;
            // background: linear-gradient(90deg, rgb(53, 51, 170) 0%, rgb(53, 51, 170) 40%, rgb(158, 43, 133) 100%);
            &.delete{
                margin-top: 2px;
            }
        }

        .ant-input-group-addon {
            .ant-input-search-button {
                border-top-right-radius: 8px !important;
                border-bottom-right-radius: 8px !important;
            }
        }

        .ant-btn.ant-btn-primary {
            height: 45px;
            padding: 12px 25px;
            line-height: 20px;

            span {
                font-size: 14px !important;
            }
        }

        .ant-card-body {
            padding: 15px 24px;
        }

        .ant-card.ant-card-bordered {
            border: 1px solid rgba(0, 0, 0, 0.1);
            border-radius: 8px;
        }

        .card {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .status-tag {
                text-align: right;
            }

            .title {
                font-weight: 600;
                font-size: 16px;
                color: rgba(27, 53, 179, 1);

            }

            .status-success {
                background: #FFFFFF;
                border: 2px solid #2F731F;
                border-radius: 50px;
                font-weight: bold;
                padding: 2px 10px;
            }

            .status-error {
                background: #FFFFFF;
                border: 2px solid #B2230F;
                border-radius: 50px;
                font-weight: bold;
                padding: 2px 10px;
            }

            .date-style {
                color: rgba(0, 0, 0, 0.6);
            }

            .sub-title {
                font-size: 14px;
                color: rgba(0, 0, 0, 0.87);
            }

            .ant-tag {
                margin-right: 0px;
            }
        }
    }

    .step2 {}

     .fundraising-listing {
        padding-top: 50px;

        .minH {
            min-height: calc(100vh - 170px);
        }

        .left {
            text-align: center;

            .ant-progress-text {
                font-weight: bold;
            }

            .ant-progress-circle.ant-progress-status-success .ant-progress-text {
                color: black;
                font-weight: bold;
            }

            .step_title {
                font-weight: bold;
                color: rgba(0, 0, 0, 1);
                font-size: 16px;
            }

            .step_subtitle {
                color: rgba(0, 0, 0, 0.6);
            }
        }

        .right {
            .title {
                font-size: 18px;
                font-weight: bold;
                color: black;
            }

            .sub-title {
                font-size: 16px;
                color: rgba(0, 0, 0, 0.6);
                width: 700px;
            }

            .content-container {
                width: 60%;

                .ant-input.ant-input-lg {
                    font-size: 14px;
                    height: 45px;
                    border: 1px solid rgba(0, 0, 0, 0.3);
                    border-radius: 4px;

                    &.ant-input-status-error {
                        border: 1px solid #ff7875;
                    }
                }

                .ant-picker-large .ant-picker-input>input {
                    font-size: 14px;
                }

                &.step3 {
                    .ant-input {
                        border: 1px solid rgba(0, 0, 0, 0.3);
                        border-radius: 4px;

                        &.ant-input-status-error {
                            border: 1px solid #ff7875;
                        }
                    }

                    .ant-picker {
                        border: 1px solid rgba(0, 0, 0, 0.3);
                        border-radius: 4px;

                        &.ant-picker-status-error {
                            border: 1px solid #ff7875;
                        }
                    }
                }

                &.step7 {
                    .payment-card-section {

                        .ant-select-selector {
                            height: auto;
                            border: 1px solid rgba(0, 0, 0, 0.3);
                            border-radius: 4px;
                            padding: 0px 20px;
                        }

                        svg {
                            font-weight: bold;
                            color: rgba(0, 0, 0, 0.6);
                            font-size: 18px;
                        }

                        .payment-card {
                            display: flex;
                            height: 100%;
                            align-items: center;

                            .text {
                                font-weight: 600;
                                font-size: 16px;
                                color: #1B35B3;
                            }
                        }
                    }
                }

                &.step8 {
                    .display-text {
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.87);
                    }

                    .display-subtext {
                        color: rgba(0, 0, 0, 0.87);

                    }

                    .ant-collapse {
                        border: none;
                    }

                    .ant-collapse-content,
                    .ant-collapse>.ant-collapse-item {
                        border-top: none;
                        border-bottom: none;
                    }

                    .ant-collapse-header {
                        color: #1B35B3;
                        font-weight: bold;
                        font-size: 16px;
                        background: white;
                        border: none;
                        border-top-right-radius: 8px;
                        border-top-left-radius: 8px;
                        padding: 20px 15px;

                        .ant-collapse-arrow {
                            font-size: 17px;
                            vertical-align: -3px;
                            font-weight: bold;
                        }

                        .anticon.anticon-edit {
                            font-size: 18px;
                            vertical-align: -4px;
                        }
                    }

                    .ant-collapse-content {
                        border-bottom-right-radius: 8px;
                        border-bottom-left-radius: 8px;
                    }

                    .preview-payment-card {
                        display: flex;
                        height: 100%;
                        align-items: center;
                        border: 1px solid rgba(0, 0, 0, 0.1);
                        border-radius: 8px;
                        padding: 20px;
                        justify-content: space-between;

                        .text {
                            font-weight: 600;
                            font-size: 16px;
                            color: rgba(0, 0, 0, 0.87);
                        }

                        .vis-ending {
                            color: rgba(0, 0, 0, 0.6);
                        }
                    }
                }
            }

            .alter-field {
                .ant-form-item-row {
                    display: block;

                    .ant-form-item-label {
                        label {
                            color: black;
                            font-weight: 600;
                        }

                        label::after {
                            display: none;
                        }
                    }
                }
            }

            .main-card {
                width: 700px;
                border: 1px solid rgba(0, 0, 0, 0.1);
                border-radius: 8px;

                &.active {
                    border: 3px solid #1B35B3;
                }
            }

            .camp-card {
                display: flex;
                align-items: center;
                justify-content: space-between;

                &.step2 {
                    align-items: baseline;
                    font-weight: 600;
                    color: rgba(0, 0, 0, 0.6);

                    .amount {
                        font-size: 16px;
                    }
                }

                &.step4 {
                    display: block;

                    .ant-input {
                        height: 45px;
                    }

                    .org-box {
                        max-height: 500px;
                        overflow: auto;
                    }
                }

                .org-list {
                    margin-top: 15px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding: 5px;

                    .box2 {
                        width: 80%;
                    }

                    .ant-avatar.ant-avatar-circle {
                        background: $button-background;
                    }

                    .box1 {
                        img {
                            width: 40px;
                            height: 40px;
                            line-height: 40px;
                            border-radius: 50%;
                        }
                    }

                    &.active {
                        border-radius: 5px;
                        border: 2px solid #1B35B3;
                    }

                    .box3 {
                        .anticon.anticon-more {
                            svg {
                                font-size: 19px;
                                font-weight: bold;
                            }
                        }
                    }
                }

                .section1,
                .section2 {
                    display: flex;
                    align-items: center;
                }

                .section2 {
                    svg {
                        font-size: 22px;
                    }
                }

                .section1 {
                    width: 80%;

                    .icon {
                        margin-right: 2rem;
                    }
                }

                .text {
                    font-weight: 600;
                    font-size: 16px;
                    color: #1B35B3;
                }

                .sub-text {
                    font-weight: 400;
                    color: rgba(0, 0, 0, 0.87);
                }


            }


            .ant-picker-large {
                height: 45px;
            }

            .qr-img {
                position: relative;
            }

            .qr-text {
                max-width: 220px;
                position: absolute;
                top: 65px;
                left: 20px;
                font-size: 20px;

            }
        }

        .admin-footer {
            background: transparent;
            border-top: 1px solid rgba(0, 0, 0, 0.1);
            width: 100%;

            .item-inline {
                display: flex;
                align-items: center;
                justify-content: space-between;
            }

            .btn-next {
                min-width: 110px;
                height: 40px;
                font-weight: 600;
            }

            .btn-back {
                color: #1B35B3;
                font-weight: 600;
            }
        }
        .top-footer {
            background: transparent;
            width: 90%;
            position: absolute;
            margin-top: -70px;

            .item-inline {
                display: flex;
                align-items: center;
                justify-content: space-between;
            }

            .btn-next {
                min-width: 110px;
                height: 40px;
                font-weight: 600;
            }

            .btn-back {
                color: #1B35B3;
                font-weight: 600;
            }
        }
    }
}

.ant-layout-header.header.fundraising-admin {
    background: transparent !important;
    color: black;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);

    .title {
        font-size: 16px;
        font-weight: 600;
    }

    .header-menu {
        display: flex;
        align-items: center;

        svg {
            color: rgba(0, 0, 0, 0.7);
            font-weight: bold;
        }
    }
}

.prize-modal {
    .ant-modal-title {
        font-weight: 600;
        font-size: 16px;
    }

    .ant-modal-header {
        border-bottom: none;
    }

    .ant-modal-body {
        padding: 12px 24px;
    }

    .ant-modal-footer {
        border-top: 1px solid rgba(0, 0, 0, 0.1);
        padding: 16px;

        .ant-btn.ant-btn-primary {
            width: 125px;
        }


        .ant-btn.ant-btn-default {
            background: transparent;
            border: none;
            box-shadow: none;
        }
    }

    .switch {
        display: flex;

        p {
            margin-left: 15px;
        }

        .ant-switch-checked {
            background-color: transparent;
            background: $button-background;
        }
    }

    .prize-radio {

        .ant-radio-wrapper,
        .ant-checkbox-wrapper {
            align-items: center;
        }

        .title {
            margin-bottom: 5px;
            font-weight: bold;
        }

        .subTitle {
            color: rgba(0, 0, 0, 0.6);
        }

        .radio-field {
            display: flex;
            align-items: center;

            img {
                margin-right: 10px;
                width: 90px;
            }

        }
    }
}

.org-modal {
    .section1 {
        border-bottom: 1px solid rgba(0, 0, 0, 0.50);
    }

    .ant-input,
    .ant-input-affix-wrapper,
    .ant-select-selector {
        border-radius: 4px !important;
    }

    .org-view {
        .title {
            font-size: 20px;
            font-weight: bold;
            color: rgba(0, 0, 0, 0.87);
        }
    }

    .org-img-section {
        text-align: right;
        margin-right: 25px;

        img {
            height: 150px;
            width: 150px;
            object-fit: cover;
            border-radius: 20px;
        }
    }

    .center {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .link {
        color: #1B35B3;
        font-weight: bold;
    }

    &.import {
        .ant-modal-footer {
            display: none;
        }
    }

    &.view-modal {
        .ant-modal-footer {
            display: none;
        }
    }
}

.step4-modal {

    .ant-input,
    .ant-input-affix-wrapper,
    .ant-select-selector {
        border-radius: 4px !important;
    }
}

.user-modal {
    .tab {
        background-color: #f0f2f5;
        margin-bottom: 5px;
        color: rgb(0 0 0 / 50%);
        padding: 15px 10px;
        width: 100%;
        display: flex;
        overflow: auto;
        align-items: center;
        justify-content: center;

        .active {
            color: rgb(0 0 0 / 100%);
            border-bottom: 3px solid transparent;
            border-image: $button-background;
            border-image-slice: 1;

        }

        p {
            padding-right: 10px;
            padding-left: 10px;
            margin-right: 10px;
            margin-left: 10px;
            margin-bottom: 0px;
            font-weight: 600;

            span {
                cursor: pointer;
                font-size: 16px;
            }
        }

        @media screen and (min-width: $screen-xxl) {
            font-size: 20px;
        }
    }

    .prize-img {
        object-fit: cover;
        width: 50px;
        height: 50px;
    }

    .ant-tabs-nav {
        display: none;
    }

    .ant-avatar.ant-avatar-circle {
        background: $button-background;
    }

    .count-box {
        margin-bottom: 15px;
        display: flex;
        justify-content: center;
        align-items: center;

        .ticket {
            width: 250px;
            text-align: center;

            .ant-card-body {
                padding: 12px;
            }
        }
    }

}

.org-tooltip {
    p {
        display: flex;
        align-items: center;
        margin-bottom: 5px;
        cursor: pointer;

        svg {
            font-weight: bold;
            color: #1B35B3;
        }
    }
}

.current_step_main_7,
.current_step_7,
.current_step_content7 {
    background: #F3F4F6 !important;
    ;
    background-color: #F3F4F6 !important;
}

.preview-org {
    display: flex;
    align-items: center;

    .box1 {
        margin-right: 20px;

        img {
            width: 50px;
            height: 50px;
            line-height: 50px;
            border-radius: 50%;
        }
    }

    .ant-avatar.ant-avatar-circle {
        background: $button-background;
    }
}


.prize-card {
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 8px;

    .ant-card-body {
        padding: 12px 24px;

        .text {
            &.black {
                color: black;
            }
        }
    }

    .prize-card-child.step5 {
        .text.black {
            font-weight: bold;
            font-size: 16x;
        }

        .ant-btn-link {
            color: rgba(27, 53, 179, 1);
            font-weight: bold;
            font-size: 14px;
        }
    }

    .existing-prize {
        display: flex;
        align-items: center;
        margin-bottom: 5px;
        margin-top: 5px;
        padding: 5px 0px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);

        &:last-child {
            border-bottom: none;
        }

        img {
            margin-right: 10px;
            height: 100px;
            width: 100px;
            object-fit: contain;
        }

        .border {
            border: 1px solid rgba(0, 0, 0, 0.1);
        }

        .text {
            font-weight: bold;
            font-size: 14px;
        }

        .sub-text {
            color: rgba(0, 0, 0, 0.6);
        }
    }
}

.fundraising-details {
    margin-bottom: 50px !important;

    .left {
        padding-right: 50px;

        .top-section {

            .id-text {
                color: rgba(0, 0, 0, 0.6);
            }

            .title-text {
                font-size: 18px;
                font-weight: bold;
                color: rgba(0, 0, 0, 1);
            }

            .title-date {
                color: rgba(0, 0, 0, 0.87);

                span {
                    color: rgba(0, 0, 0, 0.6);
                }
            }

            .ticket-text {
                color: rgba(0, 0, 0, 0.6);
                font-size: 14px;
            }

            .ant-progress-inner {
                background-color: rgba(27, 53, 179, 0.15);
            }
        }

        .tab-layout {
            .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
                color: #1B35B3;
                font-weight: 500;
            }

            .ant-tabs-tab:hover {
                color: #1B35B3;
            }

            .ant-tabs-ink-bar {
                background: #1B35B3;
            }

            .detail-section {
                .description {
                    line-height: 24px;
                    font-size: 16px;
                }

                .notification-card {
                    padding: 25px;
                    border: 1px solid rgba(0, 0, 0, 0.1);
                    border-radius: 8px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    font-weight: 500;

                    .ant-select-selector {
                        border: 1px solid rgba(0, 0, 0, 0.3);
                        border-radius: 4px;
                    }
                }

                .rule-text {
                    color: rgba(27, 53, 179, 1);
                    cursor: pointer;
                }
            }

            .billing-section,
            .ticket-section {
                .ant-skeleton-paragraph {
                    margin-bottom: 0px;

                    :first-child {
                        display: none;
                    }

                    :nth-child(2) {
                        margin-top: 0px;
                    }

                    .ant-skeleton-content .ant-skeleton-paragraph>li:nth-child(2) {}
                }

                .card-child {
                    .ant-card-body {
                        min-height: 160px;
                    }

                    &.ticket {
                        .ant-card-body {
                            min-height: 115px;
                        }
                    }

                    .parent {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                    }

                    .links {
                        margin-bottom: 15px;
                        bottom: 0;
                        position: absolute;
                        color: #1B35B3;
                        font-weight: bold;
                    }

                    p {
                        margin-bottom: 0px;
                    }
                }

                .payment-detail {
                    display: flex;
                    justify-content: space-between;
                }
            }


            .course-section {
                .course-card {
                    display: flex;
                    align-items: center;

                    .direction {
                        margin-left: auto;
                        display: inline-flex;
                    }

                    .title {
                        font-weight: bold;
                        font-size: 16px;
                        color: rgba(0, 0, 0, 0.87);
                    }

                    .sub-text {
                        color: rgba(0, 0, 0, 0.6);
                    }

                    .ant-col.ant-form-item-label {
                        padding-bottom: 4px;
                    }

                    .ant-form-item {
                        margin-bottom: 0px;
                    }

                    .draggable-icon {
                        cursor: all-scroll;
                    }

                    .ant-select-selector {
                        border: 1px solid rgba(0, 0, 0, 0.3);
                        border-radius: 4px;
                        text-transform: capitalize;
                    }

                    .mapimg{
                        width: 250px;
                        height: 125px;
                    }
                    .teeimg{
                        width: 120px;
                        height: 125px;
                    }

                }
            }

        }
    }

    .right {
        padding-left: 20px;

        .rigt-navigation {
            min-height: 400px;
            border: 1px solid rgba(0, 0, 0, 0.2);
            border-radius: 8px;
            min-width: 300px;
            margin-top: 30px;

            .parent {
                padding: 20px 30px;
            }

            .child {
                display: flex;
                align-items: center;
                margin-bottom: 15px;

                .icon {
                    width: 30px;
                    height: 30px;
                    background: rgba(0, 0, 0, 0.1);
                    align-items: center;
                    justify-content: center;
                    display: flex;
                    border-radius: 50%;
                    margin-right: 20px;
                }

                .title {
                    color: rgba(0, 0, 0, 0.6);
                }

                &.done {
                    .icon {
                        background: rgba(47, 115, 31, 0.2);
                        border: 2px solid #2F731F;

                        svg {
                            font-size: 17px;
                            color: #2F731F;
                        }
                    }

                    .title {
                        color: rgba(0, 0, 0, 0.87);
                    }
                }

                .blank {
                    svg {
                        path {
                            display: none;
                        }
                    }
                }

                &.inprogress {
                    .icon {
                        background: #fff;
                        color: rgba(0, 0, 0, 0.87);
                        border: 3px solid #2F731F;
                    }

                    .title {
                        color: rgba(0, 0, 0, 0.87);
                    }
                }
            }
        }

    }

    .bottom {
        .ticket-section {
            .ticket-table {
                .ticket-table-header {
                    // display: flex;
                    // align-items: center;
                    p{
                        width: 650px;
                    }
                    .btn-seller {
                        height: 35px;
                        padding: 0px 15px;
                        line-height: 20px;
                    }
                    .unassign{
                        width: 100%;
                        text-align: right;
                    }
                }

                .ant-table-tbody>tr>td,
                .ant-table-thead>tr>th,
                .ant-table tfoot>tr>td,
                .ant-table tfoot>tr>th {
                    padding: 10px 16px;
                }

                .ant-table-thead>tr>th[colspan]:not([colspan="1"]) {
                    text-align: left;
                }

                .table-input {
                    display: flex;
                    align-items: center;

                    :first-child {
                        margin-right: 15px;
                    }
                }

                .ant-table-content {
                    border-top-left-radius: 4px;
                    border-top-right-radius: 4px;
                }

                .batch-btn {
                    height: 28px;
                    padding: 4px 13px;
                }

                .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
                .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover {
                    border-color: #ff4d4f21;
                }

                .ant-select-status-error.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer) .ant-select-selector {
                    border-color: #ff4d4f21 !important;
                }

                .ant-table-thead>tr>th {
                    background: rgba(27, 53, 179, 1);
                    color: white;
                }

                .table-icon {
                    svg {
                        cursor: pointer;
                        color: rgba(0, 0, 0, 0.7);
                    }
                }

                .child-table {
                    .ant-table {
                        background-color: #FAFAFA;
                        ;
                        background: #FAFAFA;
                        ;
                    }

                    .ant-table-thead>tr>th {
                        background: transparent;
                    }
                }
            }
        }
    }

}

.success-modal-parent {
    .ant-modal-body {
        padding: 60px 30px;
    }

    &.payment{
        .center{
            text-align: center;
            button{
                width: 150px;
            }
        }
        .error-field{
            color: red;
            font-size: 14px;
        }
        .ant-modal-body{
            padding: 30px 30px !important;
        }
    }
    &.org {
        .ant-modal-body {
            padding: 35px;
        }
    }

    .success-modal-child {
        text-align: center;

        svg {
            fill: #2F731F;
        }

        .text {
            color: rgba(0, 0, 0, 0.7);
            line-height: 24px;
        }
    }

    .ant-modal-footer {
        border-top: 1px solid rgba(0, 0, 0, 0.1);
        padding: 16px;

        .ant-btn.ant-btn-primary {
            width: 125px;
        }


        .ant-btn.ant-btn-default {
            background: transparent;
            border: none;
            box-shadow: none;
        }
    }
    &.remaing-payment{
         
            .ant-card-body {
                padding: 15px 24px;
            }
    
            .ant-card.ant-card-bordered {
                border: 1px solid rgba(0, 0, 0, 0.1);
                border-radius: 8px;
            }
    
            .card {
                display: flex;
                align-items: center;
                justify-content: space-between;
    
                .status-tag {
                    text-align: right;
                }
    
                .title {
                    font-weight: 600;
                    font-size: 16px;
                    color: rgba(27, 53, 179, 1);
    
                }
    
                .status-success {
                    background: #FFFFFF;
                    border: 2px solid #2F731F;
                    border-radius: 50px;
                    font-weight: bold;
                    padding: 2px 10px;
                }
    
                .status-error {
                    background: #FFFFFF;
                    border: 2px solid #B2230F;
                    border-radius: 50px;
                    font-weight: bold;
                    padding: 2px 10px;
                }
    
                .date-style {
                    color: rgba(0, 0, 0, 0.6);
                }
    
                .sub-title {
                    font-size: 14px;
                    color: rgba(0, 0, 0, 0.87);
                }
    
                .ant-tag {
                    margin-right: 0px;
                }
            }
    }
}

.rule-modal-parent {
    .ant-modal-body {
        padding: 20px 35px;
    }

    .rules-regulations{
        .pdf-img{
            text-align: center;
            img{
                max-width: 100%;
                width: 750px;
            }
        }
    }
    .course-section {
        width: 750px;
        margin: 0 auto;
        margin-top: 40px;
        .course-card {
            display: flex;
            align-items: center;

            .direction {
                margin-left: auto;
                display: inline-flex;
            }

            .title {
                font-weight: bold;
                font-size: 16px;
                color: rgba(0, 0, 0, 0.87);
            }

            .sub-text {
                color: rgba(0, 0, 0, 0.6);
            }

            .ant-col.ant-form-item-label {
                padding-bottom: 4px;
            }

            .ant-form-item {
                margin-bottom: 0px;
            }

            .draggable-icon {
                cursor: all-scroll;
            }

            .ant-select-selector {
                border: 1px solid rgba(0, 0, 0, 0.3);
                border-radius: 4px;
                text-transform: capitalize;
            }

            .mapimg{
                width: 250px;
                height: 125px;
            }
            .teeimg{
                width: 120px;
                height: 125px;
            }

        }
       
    }
    

    .text {
        color: rgba(0, 0, 0, 0.7);
        line-height: 24px;
    }
    .title{
        font-size: 20px;
        font-weight: bold;
    }
    .title2{
        font-weight: 500;
    }
    .main-title{
        font-weight: bold;
        font-size: 14px;
    }
    .center-text{
        text-align: center;
    }

    .ant-modal-footer {
        border-top: 1px solid rgba(0, 0, 0, 0.1);
        padding: 16px;

        .ant-btn.ant-btn-primary {
            width: 125px;
        }


        .ant-btn.ant-btn-default {
            background: transparent;
            border: none;
            box-shadow: none;
        }
    }

    
}
@media (max-width: $screen-sm) {
    .rule-modal-parent{
        .ant-modal-body{
            padding: 5px !important;
            width: 380px;

            @media (max-width: "376px"){
                width: 350px;
            }
        }
        .course-section{
            width: 380px;
            @media (max-width: "376px"){
                width: 350px;
            }
        }
         .course-section .course-card{
            display: inline-block;
            .title{
                margin-top: 5px;
            }
            .ml5{
                margin-left: 0px;
            }
         }
        
    }
    
}


.reassign-modal-parent {
    .ant-modal-body {
        padding: 15px 30px;
    }

    .ant-radio-group.ant-radio-group-outline {
        width: 100%;
    }

    .ant-select-selector {
        border: 1px solid rgba(0, 0, 0, 0.3);
        border-radius: 4px;
    }

    .ant-input-number,
    .ant-input-number-affix-wrapper {
        width: 100%;
    }

    .reassign-modal-child {

        svg {
            fill: #2F731F;
        }

        .text {
            color: rgba(0, 0, 0, 0.7);
            line-height: 24px;
        }
    }

    .ant-modal-footer {
        border-top: 1px solid rgba(0, 0, 0, 0.1);
        padding: 16px;

        .ant-btn.ant-btn-primary {
            width: 125px;
        }


        .ant-btn.ant-btn-default {
            background: transparent;
            border: none;
            box-shadow: none;
        }
    }

    .links {
        margin-bottom: 15px;
        color: #1B35B3;
        font-weight: bold;
    }

    .draw-line {
        border-top: 1px solid rgba(0, 0, 0, 0.1);
    }
}

.contact-modal-parent {
    .ant-modal-header {
        border-bottom: none;
        padding-bottom: 0px;
    }

    .ant-select-selector {
        border: 1px solid rgba(0, 0, 0, 0.3) !important;
        border-radius: 4px !important;
    }

    .ant-modal-footer {
        border-top: 1px solid rgba(0, 0, 0, 0.1);
        padding: 16px;

        .ant-btn.ant-btn-primary {
            width: 125px;
        }


        .ant-btn.ant-btn-default {
            background: transparent;
            border: none;
            box-shadow: none;
        }
    }

    .sub-title {
        color: rgba(0, 0, 0, 0.60);
    }

    .ant-input {
        border: 1px solid rgba(0, 0, 0, 0.3) !important;
        border-radius: 4px !important;
    }
}

.fundraising-org {
    .card {
        .section2 {
            display: flex;
            align-items: center;
        }
    }
}

.link {
    color: #1B35B3;
    font-weight: bold;
}

.avatar-img {
    .ant-avatar-circle {
        background: $button-background;
        margin-right: 15px;
        // width: 40px;
        // height: 40px;
        // line-height: 40px;
        // border-radius: 50%;
    }
}