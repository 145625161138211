
@font-face {
    font-family: 'Hansief';
    src: url("../../../public/fonts/Hansief.ttf") format("truetype")
}
@font-face {
    font-family: 'Berthold';
    src: url("../../../public/fonts/Berthold_Akzidenz_Grotesk_Extra_Bold_Condensed.ttf") format("truetype")
}
@font-face {
    font-family: 'AcuminVariableConcept';
    src: url("../../../public/fonts/AcuminVariableConcept.ttf") format("truetype")
}
@import '../../../src/styles/_variables';
// @import url("../../assets/fonts/Hansief.ttf");

.preview-ticket-design {
    

    position: relative;
    $imgHeight: 349px;
    $imgWidth: 900px;
    height: $imgHeight;
    width: $imgWidth;

    .pa {
        position: absolute;
    }

    .ticket-round {
        width: 25px;
        height: 25px;
        background-color: #1B35B3;
        border-radius: 50%;
        color: white;
        font-weight: bold;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 0px;
        margin-right: 10px;
        font-size: 18px !important;

        &.cam {
            font-size: 16px !important;
            margin-right: 25px;
            position: absolute;
            margin-top: 60px;
            margin-left: 30px;
        }
    }
    
    .ticket-title {
        text-align: center;
        margin-left: 75px;
        margin-top: 55px;
        font-family: 'Hansief' !important;
        font-size: 30px !important;
        line-height: 1.1;
        font-style: normal;
        font-weight: 500;
        visibility: visible;
        letter-spacing: 0px;
        word-spacing: 0px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-transform: uppercase;
    }

    // &.valid{
    //     .ticket-title{
    //         margin-left: 60px;
    //     }
    // }

    
    .img-round {
        width: 350px;
        text-align: center;
        text-transform: uppercase;
        margin-left: 190px;
        margin-top: 125px;
        font-size: 20px;
        font-family: sans-serif;
        line-height: 1.1;
        font-style: normal;
        font-weight: bold;
        visibility: visible;
        letter-spacing: 0px;
        word-spacing: 0px;
        color: rgb(35, 31, 32);
        display: flex;
        align-items: center;
    }

    .close-date {
        right: 255px;
        line-height: 1.2;
        margin-top: 5px;
        font-family: 'Berthold';
        letter-spacing: 0px;
        word-spacing: 0px;
        color: rgb(35,31,32);
        .date {
            font-weight: bold;
            font-family: 'Berthold';
            font-size: 20px;
        }

        .text {
            text-align: left;
            font-weight: bold;
            font-size: 12px;
            line-height: 1px !important;
            margin-top: 5px;
            color: rgb(35,31,32) !important;
            font-family: 'Berthold';
        }
    }

    .ticket-no {
        position: absolute;
        bottom: 16px;
        left: 20px;
        display: flex;
        align-items: center;
        font-family: 'AcuminVariableConcept';
    }


    .second {
        position: absolute;
        bottom: 50px;
        right: 10px;
        font-size: 16px;
        font-family: 'AcuminVariableConcept';
        font-style: italic;
        width: 65px;
        font-weight: 300;
    }

    .third {
        position: absolute;
        bottom: 10px;
        right: 10px;
        font-size: 16px;
        font-family: 'AcuminVariableConcept';
        font-style: italic;
        width: 65px;
        font-weight: 300;
    }

    .first {
        position: absolute;
        bottom: 25px;
        right: 178px;
        font-size: 19px;
        font-family: 'AcuminVariableConcept';
        font-style: italic;
        width: 100px;
        text-align: left;
        font-weight: 300;
    }

    img {
        height: $imgHeight;
        width: $imgWidth;
        background-size: auto;
        border: 2px solid #cecdcd;
        z-index: 1;
    }
}