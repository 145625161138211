@import "~antd/dist/antd.min.css";
@import '../src/styles/comon.scss';
@import '../src/styles/_variables';
@import '../src/styles/_admin';

body {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    margin: 0;
    padding: 0;
}
.tab {
    @media screen and (min-width: $screen-xxl) {
        font-size: 20px;
    }
}
//576px;
@media screen and (max-width: $screen-sm) { 
   
}

// $screen-md: 768px;
@media screen and (max-width: $screen-md) { 
    
}

// $screen-lg: 992px;
@media screen and (max-width: $screen-lg) {
   
}


  
//   .StripeElement--webkit-autofill {
//     background-color: #fefde5 !important;
//   }
  
//   .product {
//     width: 100%;
//     max-width: 450px;
//     margin: auto;
//     box-shadow: 0px 15px 30px rgba(0, 0, 0, 0.4);
//     border-radius: 10px;
//     overflow: hidden;
//   }
  
//   .btn-pay {
//     display: block;
//     width: 100%;
//     border: none;
//     background: linear-gradient(
//       135deg,
//       rgb(49, 0, 62) 0%,
//       rgb(195, 40, 110) 100%
//     );
//     color: #fff;
//     padding: 10px;
//     font-size: 18px;
//     cursor: pointer;
//   }
  
//   .product-info {
//     padding: 0 16px;
//   }
  
//   h3.product-title {
//     font-size: 28px;
//     margin-bottom: 15px;
//   }
  
//   h4.product-price {
//     font-size: 24px;
//     margin: 0;
//     margin-bottom: 30px;
//     color: #777;
//     font-weight: 500;
//   }
// $screen-xxl: 1600px;