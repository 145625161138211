@import './_variables';

.main-logo {
    display: flex;
    align-items: center;

    img {
        width: 160px;
    }

    svg {
        margin-right: 15px;
        color: white;
        font-size: 24px
    }
}

.status-success {
    background: #FFFFFF;
    border: 2px solid #2F731F;
    border-radius: 50px;
    font-weight: bold;
    padding: 2px 10px;
}

.paypal-img{
    text-align: center;
    img{
        width: 300px;
    }
}
.status-error {
    background: #FFFFFF;
    border: 2px solid #B2230F;
    border-radius: 50px;
    font-weight: bold;
    padding: 2px 10px;
}

.tr {
    text-align: right;
}

.tc {
    text-align: center;
}

.ant-input-number-in-form-item {
    width: 100%;
    border-radius: 4px !important;
}

.delete-field {
    .ant-form-item-label {

        label {
            font-size: 16px;
            font-weight: 400;
        }
    }

}

.disabled-edit {
    cursor: not-allowed;
}

.cursor.disabled {
    pointer-events: none;
}

.o5 {
    opacity: 0.5;
}

#monerisCheckout {
    width: 50% !important;
    position: relative !important;
}

.fix-version {
    background: #006ac3;
    border: 1px solid gray;
    border-radius: 5px;
    font-weight: 700;
    padding: 0px;
    position: fixed;
    right: 0;
    text-align: center;
    bottom: 0;
    font-size: 14px;
    width: 100px;
    color: white;
}

.text-right {
    text-align: right;
}

.text-left {
    text-align: left;
}

.text-center {
    text-align: center;
}

/* Button CSS*/
.ant-btn.ant-btn-primary {
    background: $button-background;
    border-radius: 25px;
    border-color: transparent;
    border: none;

    &:hover {
        background: $button-background-revers;
        transition: 3s;
    }
}

.ant-btn-primary[disabled],
.ant-btn-primary[disabled]:active,
.ant-btn-primary[disabled]:focus,
.ant-btn-primary[disabled]:hover {
    color: white;
}

.ant-input[disabled] {
    color: rgba(0, 0, 0, 0.6) !important;
}

.ant-btn {
    transition: none !important;
    box-shadow: none;
}

input::placeholder {
    color: rgba(0, 0, 0, 0.4) !important;
    font-weight: 500;
}

.password-popup-main {
    padding-top: 10px;
    padding-bottom: 5px;

    p {
        margin-bottom: 0px !important;
    }

    .red {
        color: red;
    }

    .green {
        color: green;
    }

    .gray {
        color: rgba(0, 0, 0, 0.75);
    }
}

.ant-message {
    .ant-message-notice-content {
        border-radius: 10px;
        font-weight: 700;
        background: #38A169;
        color: white;
        font-size: 16px;
        padding: 12px 22px;
    }

    .error-class {
        .ant-message-notice-content {
            background: red;
            color: white;
        }
    }

    .anticon {
        font-size: 18px;
        color: white;
    }

    .custom-class {
        .ant-message-custom-content {
            display: flex;
        }

        .anticon-check-circle {
            margin-top: 3px;
        }

        p {
            margin-bottom: 0px;

            &.sub-title {
                font-size: 14px;
                font-weight: 400;
                text-align: left;
            }
        }
    }
}

.site-layout-background {
    background: #fff;
}

.ant-layout-sider-trigger {
    background: $button-background;
}

.ant-layout-has-sider {
    min-height: calc(100vh - 64px);
}

.fr {
    float: right;
}

.ant-menu-dark .ant-menu-sub,
.ant-menu.ant-menu-dark,
.ant-menu.ant-menu-dark .ant-menu-sub {
    background: transparent !important;
    // font-weight: bold;
}

.no-data {
    // min-height: 350px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-radius: 8px;
    text-align: center;
    width: 100%;
}

.underline {
    text-decoration: underline;
    text-underline-position: under;
}

.overflow {
    max-height: 500px;
    overflow: auto;
}

.privacy-policy {
    cursor: default;
}

.rule-text-signup {
    color: rgba(27, 53, 179, 1);
    cursor: pointer;
}

.new-fund-datepicker {

    .ant-picker-header-super-prev-btn,
    .ant-picker-header-super-next-btn {
        display: none !important;
    }
}

.error-img {
    max-width: 300px;

    svg {
        fill: #1B35B3;
    }
}

.ant-input-number-handler-wrap {
    display: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}

.error-content {
    text-align: center;
}

// .ant-modal-wrap{
//     z-index: 9999;
// }
.project-building-image-parent {
    display: flex;
    align-items: center;

    .project-building-image {
        margin-right: 25px;
        width: 100px;
        height: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px dashed rgba(0, 0, 0, 0.3);
        border-radius: 8px;

        &.image {
            img {
                width: 100%;
                object-fit: cover;
                height: 100%;
                border-radius: 8px;
            }
        }

        &.no-image {
            img {
                width: 35px;
                object-fit: cover;
            }

        }


    }

    p {
        margin-bottom: 10px;
    }

    button {
        border-radius: 5px;
        font-weight: 600;
    }
}

.individualImage {
    padding: 20px 25px;
    border-radius: 5px;
    border: 1px dashed;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;

    .parent-img {
        width: 500px;
        height: 200px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;

        .uploaded-img {
            object-fit: cover;
            width: 500px;
            height: 200px;
        }
    }

}

.ant-btn-primary[disabled],
.ant-btn-primary[disabled]:active,
.ant-btn-primary[disabled]:focus,
.ant-btn-primary[disabled]:hover {
    background: rgba(0, 0, 0, 0.6) !important;
}

.header {
    &.ant-layout-header {
        height: 70px;
        line-height: 70px;
        background: $button-background !important;

        .ant-avatar {
            background: white;
            color: black;
        }
    }

    .right {
        float: right;
        width: 475px;

        .header-menu {
            margin-bottom: 0px;
            padding: 0px 15px;
            font-weight: 600;
            color: white;
            text-transform: uppercase;
            cursor: pointer;
        }

        .ant-menu-overflow-item.ant-menu-item {
            font-weight: 600;
            color: white;
        }
    }

}

.pointer {
    cursor: pointer;
}

.hide-on-print {
    display: block;
}

.print-invoice {
    padding: 0px 100px;
    margin-bottom: 20px;

    .parent {
        display: flex;
        width: 100%;
    
        .left,
        .right {
            width: 50%;
    
            p {
                margin-bottom: 0px;
            }
        }
    
        img {
            width: 320px;
        }
    
    }
    .table1{
        table{
            width: 100%;
            text-align: left;
            tr th{
                background-color: #1B35B3;
                color: white;
            }
            tr th, tr td{
                padding: 0px 10px;
            }
        }
    }
}



@media print {

    html,
    body {
        height: initial !important;
        overflow: initial !important;
        -webkit-print-color-adjust: exact;
    }

    .logo-height {
        max-height: 100px;
    }

    .hide-on-print {
        display: block;
    }


    .hidden {
        display: block !important;
        margin-top: 60px;
    }

    .on-print-right {
        text-align: right !important;
    }

    .on-print-left {
        text-align: left;
    }

    @page :footer {
        display: none;
    }

    @page :header {
        display: none;
    }
}

@page {
    size: auto;
    /* auto is the initial value */
    margin: 0mm;
    /* this affects the margin in the printer settings */
}

.video-react-big-play-button {
    display: none !important;
}

.flex-center {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
}

.border {
    border: 1px solid rgba(0, 0, 0, 0.1);
    // margin-top: 5px;
    // margin-bottom: 5px;
}

.maxw100 {
    max-width: 100%;
}

.title-case {
    text-transform: capitalize;
}

.iframe {
    height: calc(100vh - 100px);
}

.w100 {
    width: 100%;
}

.ant-spin-dot-item {
    background-color: #782e92;
    opacity: 0.6;
}

.black {
    color: black;
}

.red {
    color: red;
}

.line {
    width: 650px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    position: absolute;
    margin: 0 auto;
}

.bold {
    font-weight: bold;
}

.cmp-title {
    color: rgba(0, 0, 0, 0.87);
    font-weight: bold;
    font-size: 16px;
}

.cmp-sub-element {
    color: gray;
    font-size: 12px;
}

.cmp-sub-title {
    color: rgba(0, 0, 0, 0.6);
}

.b500 {
    font-weight: 500;
}

.lh {
    line-height: 22px;
}

.success {
    color: green;
}

.error {
    color: red;
}

$sizes: 120;

@mixin margin-classes {
    @for $i from 0 through $sizes {
        $margin: $i * 0.25rem;

        /* margin #{$margin} */
        .m#{$i} {
            margin: $margin;
        }

        .ml#{$i} {
            margin-left: $margin;
        }

        .mr#{$i} {
            margin-right: $margin;
        }

        .mt#{$i} {
            margin-top: $margin;
        }

        .mb#{$i} {
            margin-bottom: $margin;
        }

        .mx#{$i} {
            margin-left: $margin;
            margin-right: $margin;
        }

        .my#{$i} {
            margin-top: $margin;
            margin-bottom: $margin;
        }
    }
}

@include margin-classes;


@mixin padding-classes {
    @for $i from 1 through $sizes {
        $padding: $i * 0.25rem;

        /* padding #{$padding} */
        .p#{$i} {
            padding: $padding;
        }

        .pl#{$i} {
            padding-left: $padding;
        }

        .pr#{$i} {
            padding-right: $padding;
        }

        .pt#{$i} {
            padding-top: $padding;
        }

        .pb#{$i} {
            padding-bottom: $padding;
        }

        .px#{$i} {
            padding-left: $padding;
            padding-right: $padding;
        }

        .py#{$i} {
            padding-top: $padding;
            padding-bottom: $padding;
        }
    }
}

@include padding-classes;

@mixin border-classes {
    @for $i from 1 through $sizes {
        $padding: $i+px;

        /* padding #{$padding} */
        .br#{$i} {
            border-radius: #{$i}px !important;
        }
    }
}

@include border-classes;

@mixin font-classes {
    @for $i from 1 through $sizes {
        $padding: $i+px;

        /* padding #{$padding} */
        .fs#{$i} {
            font-size: #{$i}px !important;
        }
    }
}

@include font-classes;


/* Scroll bar style*/

::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}

::-webkit-scrollbar-thumb {
    min-width: 70px;
    min-height: 70px;
    border-radius: 50px;
    background: rgba(0, 0, 0, 0.13);
}

::-webkit-scrollbar-thumb:hover {
    background: rgba(0, 0, 0, 0.23);
}

::-webkit-scrollbar-track {
    background: transparent;
}

.stripe-element {
    width: "700px";
    max-width: "700px"
}

.StripeElement,
.card-holder-name {
    margin-top: 22px;
    padding: 14px 10px;
    color: black;
    background-color: white;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 4px;
}

.ant-menu-submenu-title {
    padding-left: 16px !important;
}

.ant-menu-sub {
    .ant-menu-item {
        padding-left: 30px !important;
    }
}

.validate-page {
    .check {
        p {
            margin-bottom: 0px;
        }

        .icon {
            width: 30px;
            height: 30px;
            border-radius: 50%;
            background: #1B35B3;
            color: white;
            font-size: 16px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 15px;
            font-weight: bold;
        }

        .section {
            display: flex;
            margin-bottom: 15px;
        }

        .main {
            font-weight: 400;
        }

        .sub-main {
            font-weight: bold;
            font-size: 16px;
        }
    }

    .ticket-section {
        position: fixed;
        bottom: 15px;
        width: 100%;
        text-align: center;
        z-index: 9999;
        opacity: 1;

        .btn {
            border-radius: 32px;
            width: 150px;
        }

        .title {
            font-weight: 600;
            margin-bottom: 0px;
            padding-bottom: 15px;
        }

        .validate-no {
            border: 2px solid #1B35B3;
            color: #1B35B3;
        }

        .validate-another {
            width: 300px;
            background-color: #1B35B3 !important;
            background: #1B35B3 !important;
            color: white;
        }

        .validate-yes {
            background-color: #1B35B3 !important;
            background: #1B35B3 !important;
            color: white;
        }


    }

    .validate-section {
        padding-left: 100px;
        padding-right: 100px;
        // height: calc(100vh - 120px);
        max-height: calc(100vh - 240px);
        overflow: auto;

        &.validate {
            margin-top: 50px;
        }

        .prize-card-child {
            img {
                height: 180px !important;
                width: 290px !important;
                background-size: cover;
            }
        }

        .title {
            font-weight: 600;
            font-size: 24px;
            line-height: 36px;
            color: #a32b83;
            margin-bottom: 15px;
            text-align: center;
        }

        .description {
            line-height: 24px;
            font-size: 16px;
            margin-bottom: 25px;
        }





    }

    .main-container {
        .title-text {
            font-weight: bold;
            color: rgba(0, 0, 0, 0.87);
            font-size: 20px;
        }

    }

    .validate-container {
        width: 100%;
        height: 500px;
        position: relative;

        .title-text {
            font-weight: bold;
            color: rgba(0, 0, 0, 0.87);
            font-size: 20px;
        }

        .validate-prize {
            text-align: center;
        }

        .slick-slider {
            button {
                background: #1B35B3;
            }
        }

        .slick-slider {
            .anticon {
                color: #1B35B3;
            }
        }

        .arrow-right {
            position: absolute;
            right: -25px;
            top: 68px;
            font-size: 25px;
        }

        .slick-slide img {
            display: inline;
        }

        .arrow-left {
            position: absolute;
            left: -25px;
            top: 68px;
            font-size: 25px;
        }

        .rule-text {
            color: white;
        }

        .prize-win-title {
            letter-spacing: 2px;
            font-weight: 500;
        }

        .prize-win-count {
            font-size: 12px;
            color: rgba(0, 0, 0, 0.75);
        }

        .text-title {
            font-weight: bold;
            font-size: 16px;
        }

    }

    .outer-box {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background: linear-gradient(20.76deg, #E340BE 16.33%, #1B35B3 92.18%);
    }

    .inner-box {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        opacity: 1;

        .inner-data {
            padding: 25px 15px;
        }
    }

    .overlay {
        z-index: 99999;
        z-index: inherit;
    }

    .validate-message {
        text-align: center;
        padding: 20px 50px;

        &.info-message {
            width: 100%;
        }

        &.info-success {
            background: rgba(47, 115, 31, 0.15);
            color: #2F731F;
        }

        &.info-error {
            background: rgba(178, 35, 15, 0.15);
            color: #B2230F;
        }

        .ticket-icon {
            font-size: 40px;
            margin-bottom: 10px;
        }

        .ticket-title {
            font-size: 16px;
        }

        .ticket-msg {
            font-weight: bold;
            font-size: 18px;
        }
    }

    @media (max-width: $screen-md) {
        .validate-section {
            padding-left: 20px;
            padding-right: 20px;

            .prize-card-child {
                img {
                    height: 180px;
                    width: 290px;
                    background-size: cover;
                }
            }
        }

        .d-flex {
            justify-content: center;
        }
    }
}


.custom-div-ticket {
    max-width: 100%;
    overflow: auto;
    margin-top: 20px;
    margin-bottom: 50px;
}

.d-flex-a-center {
    display: flex;
    align-items: center;
}
.space-between{
    justify-content: space-between;
}