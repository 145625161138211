@import '../../../styles/_variables';

.d-flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.ant-layout {
    background-color: #fff !important;
    background: #fff !important;
}
.content-layout{
    background-color: #EDEDED;;
}
.ant-layout-sider{
    border-right: 2px solid transparent;
    border-image: $button-background;
    border-image-slice: 1;
}
.ant-menu-item{
    padding-left: 15px !important;
    // display: flex !important;
}
.ant-menu-item.ant-menu-item-selected::after{
        border-right: 2px solid transparent;
        border-image: $button-background;
        border-image-slice: 1;
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected{
    background: linear-gradient(90deg, rgba(158, 43, 133, 0.3) 0%, rgba(158, 43, 133, 0.3) 40%, rgba(53, 51, 170, 0.3) 100%) !important;
    font-weight: bold;
    color: black;
}